import React, { useState } from "react"
import qs from "qs"
import Layout from "../components/page/layout"
import SEO from "../components/page/seo"
import { Flex, Box, Button, Label, Input, Textarea } from "theme-ui"

import theme from "../components/page/theme"

import { navigate } from "gatsby-link"
import { ThemeProvider } from "theme-ui"

import SocialMedia from "../components/page/socialMedia"

const ContactPage = ({ location }) => {
  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const name = qs.parse(location.search.replace("?", "")).name || ""

  const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)

  const [state, setState] = useState({})

  const handleChange = e => {
    if (e.target.name === "name") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        subject: `You've got mail from ${e.target.value}!`,
      })
    } else {
      setState({ ...state, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <h1>Hello, there {capitalizedName && `${capitalizedName}!`}</h1>
        <SEO title="Contact" />
        <p>
          If you're looking to get in touch with me you're in the right place!
          The best way to get in touch with me is to fill out the form below.
        </p>
        <SocialMedia placement="contact-page" />
        <Box
          as="form"
          method="post"
          name="contact-form"
          onSubmit={handleSubmit}
          py={3}
          data-netlify="true"
          action="/thanks/"
          data-netlify-honeypot="bot-field"
        >
          <Input type="hidden" name="form-name" value="contact-page" />
          <Input type="hidden" name="subject" value={`You've got mail`} />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <Flex mx={-2} mb={3}>
            <Box sx={{ width: "100%" }}>
              <Label htmlFor="name">Name *</Label>
              <Input
                id="name"
                name="name"
                required
                placeholder="Katherine Johnson"
                onChange={handleChange}
              />
            </Box>
          </Flex>

          <Flex mx={-2} mb={3}>
            <Box sx={{ width: "100%" }}>
              <Label htmlFor="organization-company">
                Organization/Company *
              </Label>
              <Input
                id="organization-company"
                name="organization-company"
                required
                placeholder="NASA"
                onChange={handleChange}
              />
            </Box>
          </Flex>

          <Flex mx={-2} mb={3}>
            <Box sx={{ width: "100%" }}>
              <Label htmlFor="email">E-mail Address *</Label>
              <Input
                id="email"
                name="email"
                placeholder="kathy@spaceships.com"
                required
                onChange={handleChange}
              />
            </Box>
          </Flex>
          <Flex mx={-2} mb={3}>
            <Box sx={{ width: "100%" }}>
              <Label htmlFor="message">Message *</Label>
              <Textarea
                id="message"
                name="message"
                onChange={handleChange}
                required
              ></Textarea>
            </Box>
          </Flex>
          <Flex mx={-2} flexWrap="wrap">
            <Box px={2} ml="auto">
              <Button type="submit">Submit</Button>
            </Box>
          </Flex>
        </Box>
      </Layout>
    </ThemeProvider>
  )
}

export default ContactPage
